<template>
  <div>
    <!--工具栏-->
    <div id="toolbar" ref="toolbar">
      <div id="toolsbarLeft" ref="toolsbarLeft">
        <!--标题-->
        <Icon type="logo-codepen" size="28" v-show="titleShow"/>
        <div style="margin-top:3px;padding:0 5px;font-size:16px;margin-right:15px;white-space:nowrap;"
             v-show="titleShow" ref="title"  id="title">
          <slot name="title"></slot>
        </div>

        <slot name="toolsbarLeft">
          <!--靠左工具-->
        </slot>
      </div>
      <!--      头部-->
      <div id="announce" ref="announce">
        <slot name="announce"></slot>
      </div>
    </div>
    <!--编辑区-->
    <div id="contentarea" ref="contentarea">
      <slot name="contentArea">
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      titleShow: false,
    }
  },
  mounted() {
    if (this.$refs.title.innerText != "") {
      this.titleShow = true;
    }
  }
}
</script>

<style scoped>
#toolbar {
  display: flex;
  flex-direction: column;
}

#announce {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

#announce > .left > Button {
  margin-left: 15px;
}

/deep/ #title > .info-wrapper > div > form > .ivu-form-item {
  margin-bottom: 0;
}

#title {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
#title > .center {
  margin-left: 50px;
}

#title > .center > span:first-child {
  margin-right: 50px;
}

#title > .center > span > i {
  font-style: normal;
  color: red;
  font-size: 18px;
}

#announce > .right > Button {
  margin-right: 15px;
}

#toolbar {
  min-height: 50px;
  background: #fafafa;
  top: 0;
  position: fixed;
  min-width: 1200px;
  width: 100%;
  box-shadow: 0 0 1px 1px #ddd;
  z-index: 2;
}

#toolsbarLeft {
  margin: 10px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

#toolsbarRight {
  margin: 10px 10px 10px -47px;
  float: right;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
}

#toolsbarRight button, #toolsbarLeft button {
  margin: 0 5px;
}

/*#toolsbarRight >Form{*/
/*  background: red;*/
/*  display: flex;*/
/*}*/
#contentarea {
  top: calc(60px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 10px 10px 15px;
  line-height: 1.5em;
  overflow: auto;
  z-index: 1;
}

.tooldiv {
  line-height: 30px;
  text-align: right;
  white-space: nowrap;
}
</style>
